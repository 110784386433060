const report_perms = {
    view_topsheet: "report.view_topsheet",
    view_report_menu: "report.view_report_menu",
    view_report_all_branch_menu: "report.view_report_all_branch_menu",
    view_report_all_zone_menu: "report.view_report_all_zone_menu",
    view_report_all_circle_menu: "report.view_report_all_circle_menu",
    view_report_branch_menu: "report.view_report_branch_menu",
    view_report_zone_menu: "report.view_report_zone_menu",
    view_report_circle_menu: "report.view_report_circle_menu",
    view_report_branch_day_wise: "report.view_report_branch_day_wise",
    view_report_branch_non_day_wise: "report.view_report_branch_non_day_wise",
    view_report_zone_day_wise: "report.view_report_zone_day_wise",
    view_report_zone_non_day_wise: "report.view_report_zone_non_day_wise",
    view_report_circle_day_wise: "report.view_report_circle_day_wise",
    view_report_circle_non_day_wise: "report.view_report_circle_non_day_wise",
    view_report_names: "report.view_report_names",
    view_report_head_office_day_wise: "report.view_report_head_office_day_wise",
    view_report_head_office_non_day_wise: "report.view_report_head_office_non_day_wise",
    view_report_head_office_menu: "report.view_report_head_office_menu",

    view_report_own_branch_day_wise: "report.view_report_own_branch_day_wise",
    view_report_own_branch_non_day_wise: "report.view_report_own_branch_non_day_wise",
    view_report_own_branch_menu: "report.view_report_own_branch_menu",

    view_report_own_zone_day_wise: "report.view_report_own_zone_day_wise",
    view_report_own_zone_non_day_wise: "report.view_report_own_zone_non_day_wise",
    view_report_own_zone_menu: "report.view_report_own_zone_menu",

    view_report_own_circle_day_wise: "report.view_report_own_circle_day_wise",
    view_report_own_circle_non_day_wise: "report.view_report_own_circle_non_day_wise",
    view_report_own_circle_menu: "report.view_report_own_circle_menu",
}

export default report_perms;