const parsing_functions = {
    parse_date_to_standard: parse_date_to_standard,
    hex2blob: hextoblob,
    currency_format: new Intl.NumberFormat("en-IN", {maximumFractionDigits: 2, minimumFractionDigits: 2})
}

function parse_date_to_standard(value) {
    let date_parts = value.split("/")
    let date = new Date(date_parts[2], date_parts[1] - 1, date_parts[0])
    return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getDate()
}

function hextoblob(hexdata, type_str) {
    let byteArray = new Uint8Array(hexdata.length / 2);
    for (let x = 0; x < byteArray.length; x++) {
        byteArray[x] = parseInt(hexdata.substr(x * 2, 2), 16);
    }
    let blob = new Blob([byteArray], {type: type_str});
    return blob
}

export default parsing_functions;